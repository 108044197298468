<template>
  <div>
    <HeroSection 
      :title="'SIGKO International'"
      :title-class="'title is-dark slide-in-text'"
      :subtitle="'Import & Export'"
      :section-class="'hero is-shapped is-link is-medium is-bold'"
      :is-home-page-with-logo="true"
    /> 
    <section class="section has-background-white mt-0">
      <div class="container has-slide-in">
        <div class="is-vcentered columns is-multiline">

          <FrontViewComponent
            :title="$t('products.lobster.name')"
            :description="$t('products.lobster.description')"
            :route="'/products/lobster'"
            :image-src="lobsterImagePath"
            :route-button-text="$t('navigation.link')"/>

          <FrontViewComponent
            :title="$t('products.coals.name')"
            :description="$t('products.coals.description')"
            :route="'/products/coals'"
            :image-src="coalImagePath"
            :route-button-text="$t('navigation.link')"/>

        </div>
      </div> 
    </section>  
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection.vue'
import FrontViewComponent from '@/components/FrontViewComponent.vue';

export default {
  name: 'HomeView',
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'SIGKO International - Import & Export'
    }, {
      name: 'keywords',
      content: 'SIGKO International, Import, Export, Lobster, Coals, Cuban Coals, Argus Lobster, sigko, sigko.eu, marabu, charcoals, charcoal, cuban charcoal, cuban coals, argus lobster, argus lobsters, sigko international, sigko international import export'
    }, {
      property: 'og:title',
      content: 'SIGKO International - Import & Export'
    }]
  },
  components: {
    HeroSection,
    FrontViewComponent
  },
  data() {
    return {
      lobsterImagePath: require('@/assets/argus-lobster.png'),
      coalImagePath: require('@/assets/cuban-coal.jpg'),
      newImages: new Array()
    }
  },
}
</script>

<template>
    <div>
      <HeroSection 
        :title="'Ooops! Page Not Found'"
        :title-class="'title is-dark slide-in-text'"
        :subtitle="'SIGKO International'"
        :section-class="'hero is-shapped is-link is-fullheight is-bold'"
      /> 
    </div>
  </template>
  
  <style lang="scss" scoped>
  
  </style>
  
  <script>
  // @ is an alias to /src
  import HeroSection from '@/components/HeroSection.vue'
  
    export default {
    name: 'HomeView',
    components: {
      HeroSection,
    },
  }
  </script>
  
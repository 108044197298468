<template>
    <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
            <span class="icon">
                <font-awesome-icon icon="globe" />
            </span>
            <span class="is-hidden-desktop">{{ $t('languageSelector.title') }}</span>
        </a>
        <div class="navbar-dropdown is-right ">
            <a class="navbar-item" @click="changeLanguage('en')">
                <country-flag country='gb' />
                <span class="lang">
                    <button>{{ $t('languageSelector.languages.en') }}</button>
                </span>
            </a>
            <a class="navbar-item" @click="changeLanguage('el')">
                <country-flag country='gr' />
                <span class="lang">
                    <button>{{ $t('languageSelector.languages.el') }}</button>
                </span>
            </a>
            <a class="navbar-item" @click="changeLanguage('es')">
                <country-flag country='es' />
                <span class="lang">
                    <button >{{ $t('languageSelector.languages.es') }}</button>
                </span>
            </a>
        </div>
    </div>
</template>

<style scoped lang="scss">

@use "bulma/sass/utilities/mixins";

@include mixins.until(1023px) {
    .navbar-dropdown {
        .navbar-item {
            span {
                &.lang {
                    position: relative;
                    top: -0.75rem;
                }
            }
        }
    }
}
.navbar-item {
    .navbar-link{
        border-radius: 0.5rem;
    }
}
</style>

<script>
import CountryFlag from 'vue-country-flag'

export default {
    name: 'LanguageSelector',
    components: {
        CountryFlag
    },
    methods: {
        changeLanguage(locale) {
            localStorage.setItem('locale', locale);
            this.$i18n.locale = locale;
        }
    }
}
</script>
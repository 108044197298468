<template>
    <div class="has-slide-in">
      <section :class="sectionClass">
        <div class="hero-body no-user-select">
          <div :class="heroContainerClass">
            <span v-if="!isHomePageWithLogo">
              <h1 :class="titleClass">
                {{ title }} 
              </h1>
              <hr v-if="hasSpacer" :class="{'hr-sigko': true, 'is-white': !hasSpacerOrange, 'is-orange': hasSpacerOrange, 'slide-in-text': true }"/>
              <h2 v-if="subtitle &&  subtitle.trim() !== ''" class="subtitle slide-in-text-from-right">
                {{ subtitle }}
              </h2>
            </span>
            <span v-else class="sigko-logo">
              <!-- <figure class="image is-2by1 m-auto">
                <img src="@/assets/logo-white.png" alt="SIGKO Logo" class="has-ratio"/>
              </figure> -->
              <h1 :class="titleClass">
                SIGKO
              </h1>
              <h3 class="subtitle slide-in-text-from-right is-uppercase" style="padding-bottom: 0; margin-bottom: 0;;">
                International
              </h3> 
              <h4 class="slide-in-text is-uppercase">
                import & export
              </h4>
            </span>
          </div>
        </div>
      </section>
    </div>
  </template>
  
<style lang="scss" scoped>
$sigko-blue: #1f5daa;
$sigko-black: #343031;
$sigko-orange: #d6682d;
$sigko-blue-new: #253d6d;

.hero{

  &.is-shapped {
    // -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 75%);
    // clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 75%);
    background-image: url('@/assets/dottedworld.svg') !important; /* Vue's @ alias for src folder */
    background-size: cover; /* Optional: adjust based on your needs */
    background-position: center; /* Optional: adjust based on your needs */
    background-repeat: no-repeat;    /* Prevents the image from repeating */
    // border-bottom: solid 0.5rem $sigko-black;
    border-bottom: solid 0.07rem $sigko-orange;
    // border-top: solid 0.25rem $sigko-black;
    .hero-body {
      // background-color: rgb(31, 93, 170, 0.6);
      // background-color: rgb(39, 61, 120, 0.6);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.has-background-sea {
    background-image: url('@/assets/sea.png') !important; /* Vue's @ alias for src folder */
    background-size: cover; /* Optional: adjust based on your needs */
    background-position: center; /* Optional: adjust based on your needs */
    background-repeat: no-repeat;    /* Prevents the image from repeating */
    border-bottom: solid 0.5rem $sigko-black;
    // border-top: solid 0.25rem $sigko-black;
  }
  &.has-background-flame {
    background-image: url('@/assets/forrest.jpg') !important; /* Vue's @ alias for src folder */
    background-size: cover; /* Optional: adjust based on your needs */
    background-position: center; /* Optional: adjust based on your needs */
    background-repeat: no-repeat;    /* Prevents the image from repeating */
    .hero-body {
      background-color: rgba(0, 0, 0, 0.4);
      // background-color: unset;
    }
  }
  .hero-body {
    overflow: hidden;
    .title {
      font-size: 3.25rem;
      //text-transform: uppercase;
      background-clip: text;
      text-shadow: -4px 2px 4px rgba(52,48,49,1);
    }
    .subtitle {
      font-size: 2rem;
      text-shadow: -4px 2px 4px rgba(52,48,49,1);
    }

    .sigko-logo {
      .title {
        font-size: 5rem;
        letter-spacing: 0.75rem;
        text-shadow: -4px 2px 4px rgba(52,48,49,1);
      }
      .subtitle {
        font-weight: bold;
        color: $sigko-orange;
        text-shadow: -4px 2px 4px  rgba(52,48,49,1);
      }
      h4 {
        text-shadow: -4px 2px 4px rgba(52,48,49,1);
        padding: 0;
        margin: 0;
      }
    }
  }
}

.slide-in-text {
    position: relative;
    opacity: 0;
    animation: slideIn 1s forwards;
}

.slide-in-text-from-right {
    position: relative;
    opacity: 0;
    animation: slideInFromRight 1s forwards;
}
</style>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: false
      },
      titleClass: {
        type: String,
        default: "title is-dark slide-in-text-from-right"
      },
      sectionClass: {
        type: String,
        default: "hero is-info is-medium is-bold"
      },
      heroContainerClass: {
        type: String,
        default: "container has-text-centered"
      },
      hasSpacer: {
        type: Boolean,
        default: false
      },
      hasSpacerOrange: {
        type: Boolean,
        default: false
      },
      isHomePageWithLogo: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
  
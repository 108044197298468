<template>
    <div v-if="isLoading" class="pageloader is-active">
        <span class="title">{{ loaderContent }}</span>
    </div>
</template>

<script>

export default {
    name: 'PageLoader',
    computed: {
        isLoading() {
            return this.$store.state.isLoading;
        },
        loaderContent() {
            return this.$store.state.loaderContent;
        }
    }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    isLoading: false,
    loaderContent: '',
    isNavBarActive: false
  },
  getters: {
    getNavBarState: (state) =>{
      return state.isNavBarActive;
    }
  },
  mutations: {
    setLoading(state, isLoading, content) {
      state.isLoading = isLoading;
      state.loaderContent = content;
    },
    setRouteChanging(state, isRouteChanging) {
      state.isLoading = isRouteChanging;
      state.isNavBarActive = isRouteChanging;
    },
    setNavBarActive(state, isActive) {
      state.isNavBarActive = isActive;
    }
  },
  actions: {
  },
  modules: {
  }
})

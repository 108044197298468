import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
import NotFound from '../views/NotFound.vue';

/* eslint-disable */
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
      breadcrumb: 'About'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    meta: {
      title: 'About',
      breadcrumb: 'About'
    }
  },
  {
    path: '/products',
    name: 'products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
    },
    meta: {
      title: 'Products',
      breadcrumb: 'Products'
    }
  },
  {
    path: '/products/lobster',
    name: 'cubanlobster',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "lobster" */ '../views/Products/CubanLobsterView.vue')
    },
    meta: {
      title: 'Cuban Lobster',
      breadcrumb: 'Products'
    }
  },
  {
    path: '/products/coals',
    name: 'marabucoals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "coals" */ '../views/Products/MarabuCoalsView.vue')
    },
    meta: {
      title: 'Marabu Coals',
      breadcrumb: 'Products'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
    },
    meta: {
      title: 'Contact',
      breadcrumb: 'Contact'
    }
  },
  {
    path: '/comming-soon',
    name: 'comming-soon',
    component: function () {
      return import(/* webpackChunkName: "commingsoon" */ '../views/CommingSoon.vue')
    },
    meta: { 
      hideNavbarAndFooter: true 
    },
  },
  // Catch all unmatched routes
  {
    path: '/:pathMatch(.*)*', // Use this syntax for Vue Router 4
    name: 'NotFound',
    component: NotFound,
    meta: { 
      hideNavbarAndFooter: true 
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
})

router.beforeEach((to, from, next) => {
  document.title = `SIGKO International - ${to.meta.title}` || 'SIGKO International';
  // Set the route changing flag to true
  store.commit('setRouteChanging', true);
  // Continue with the navigation
  next();
});

router.afterEach((to, from, next) => {
  // Simulating a delay with setTimeout
  window.scrollTo(0, 0);
  setTimeout(() => {
    // Set the route changing flag to true
    store.commit('setRouteChanging', false);
  }, 450);
});


export default router

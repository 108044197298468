// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        navigation: {
            home: 'Home',
            about: 'About',
            products: 'Products',
            contact: 'Contact',
            footer: {
                home: 'Home',
                about: 'About',
                products: 'Products',
                contact: 'Contact'  
            },
            link: 'More Info'
        },
        languageSelector: {
            title: 'Language',
            languages: {
                en: 'English',
                el: 'Greek',
                es: 'Spanish'
            }
        },
        download: "Presentation Download",
        products: {
            title: 'Products',
            header: {
                info: 'Information',
            },
            lobster: {
                name: 'Cuban Lobster',
                subtitle: 'The taste and quality of the Caribbean on your plate!',
                description: 'The Panulirus argus Lobster has a brownish-green shell with yellow and white spots, turning red when cooked. Its meat iswhite, sweet, tender, and mildly flavored.',
                content: {
                    section_one:'The Panulirus argus Lobster has a brownish-green shell with yellow and white spots, turning red when cooked. Its meat is white, sweet, tender, and mildly flavored.',
                    section_two:'Lobster slaughtered with induced death in 0.65% sodium or potassium metabisulfite solution and 0.5% common salt for an approximate time of 15 minutes, and frozen on board.',
                    categories: {
                        title: 'Categories',
                        subtitle: 'Spiecies;',
                        spiecies: [
                            {
                                title: 'Panulirus Argus',
                                subtitle: 'Trademark;'
                            },
                            {
                                title: 'Caribbean Queen',
                                subtitle: '*both frozen',
                                list: [
                                    'Whole Lobster',
                                    'Lobster Tail'
                                ]
                            }
                        ]
                    },
                    packaging: {
                        title: 'Packaging',
                        list: [
                            'The lobster is loaded in the Port of “Avana” (Mariel)',
                            'Storage temperature: -18.0ºC, Durability 24 months.',
                            'The Panullirus Argus Lobster exceed in meat content.'
                        ],
                        content: [
                            {
                                title: 'Whole Lobster',
                                image: 'wholeLobster',
                                header: [
                                    'gr/pc',
                                    'kg/package'
                                ],
                                content: [{
                                    key: '300/350',
                                    value: '10kg'
                                },{
                                    key: '350/400',
                                    value: '10kg'
                                },{
                                    key: '400/460',
                                    value: '10kg'
                                },{
                                    key: '460/520',
                                    value: '10kg'
                                },{
                                    key: '520/575',
                                    value: '10kg'
                                },{
                                    key: '575/630',
                                    value: '10kg'
                                },{
                                    key: '630/740',
                                    value: '10kg'
                                }, {
                                    key: '740/860',
                                    value: '10kg'
                                }, {
                                    key: '860/1100',
                                    value: '10kg'
                                }, {
                                    key: '1100/1300',
                                    value: '10kg'
                                }, {
                                    key: '1300/1500',
                                    value: '9kg'
                                }, {
                                    key: '1500/2000',
                                    value: '8kg'
                                }, {
                                    key: '2000/2500',
                                    value: '8kg'
                                }, {
                                    key: '2500/3000',
                                    value: '15kg'
                                }],
                                dimensions: {
                                    description: 'Packing: cardboard box with dimensions:',
                                    value: '565 x 405 x 165 mm'
                                }
                            },
                            {
                                title: 'Raw Tail',
                                header: [
                                    'gr/pc',
                                    'kg/package'
                                ],
                                image: 'rawTail',
                                content: [{
                                    key: '3/4',
                                    value: '20kg'
                                }, {
                                    key: '4/5',
                                    value: '20kg'
                                }, {
                                    key: '5/6',
                                    value: '20kg'
                                }, {
                                    key: '6/7',
                                    value: '20kg'
                                }, {
                                    key: '7/8',
                                    value: '20kg'
                                }, {
                                    key: '8/9',
                                    value: '20kg'
                                }, {
                                    key: '9/10',
                                    value: '20kg'
                                }, {
                                    key: '10/12',
                                    value: '20kg'
                                }, {
                                    key: '2/14',
                                    value: '20kg'
                                }, {
                                    key: '14/16',
                                    value: '20kg'
                                }, {
                                    key: '16/20',
                                    value: '20kg'
                                }, {
                                    key: '20/24',
                                    value: '20kg'
                                }],
                                dimensions: {
                                    description: 'Packaging: 20kg per cardboard.',
                                    value: ''
                                }
                            }
                        ],

                    }
                },
                products: {
                    offer: {
                        buttonText: 'Obtain an offer',
                        title: 'Obtain an offer',
                        form: {
                            sendButton: 'Submit',
                            cancel: 'Cancel',
                        }
                    },
                    wholeLobster: 'Whole Lobster',
                    rawTail: 'Raw Tail',
                    headers: {
                        weight: 'gr/pc',
                        package: 'kg/package',
                        quantity: 'Quantity in kg',
                    }
                }
            },
            coals: {
                name: 'Cuba\'s charcoal',
                subtitle: '100% natural product with high-quality burn for better, healthier grilling!', 
                description: "Cuba's charcoal is among the best charcoal on the market. The best choice for professionals and private individuals. It lights easily, without sparking, and does not leave residue.",
                info: 'Coals are a great way to cook your food outdoors.',
                content: {
                    section_one: "Cuba's charcoal is among the best charcoal on the market. The best choice for professionals and private individuals. It lights easily, without sparking, and does not leave residue. It is a 100% natural product, free from chemical additives.",
                    section_two: "It consists of large, solid pieces, without moisture, dust, residue, or foreign bodies.",
                    section_three: "The charcoal, as the older generation knows, is made from young, small-diameter trees and shrubs. Its ideal shape, cylindrical and long, combined with its uniformity and purity, makes it the perfect natural charcoal. It is suitable for cases where a short wait time is needed to start grilling, as well as for stable burning with a long duration (4-6 hours).",
                    section_four: "Cuba's charcoal is mainly produced from the plant ‘dichrostachys cinerea’, with a name derived from Ancient Greece (di-, ‘twice’), χροός (kroos, ‘color’), and στάχυς (stachys), and is now widely known as marabu.",
                    section_five: "The plant originates from Africa and was first introduced to the Caribbean, specifically to Cuba, in the 19th century. The plant’s noticeable features are its two-toned cylindrical branches. The plant species produces a durable and hard wood that burns slowly without toxicity, and is thus often used for firewood. It is ideally suited for producing charcoal.",
                    section_six: "The marabu variety is ideal for professionals who seek a perfect culinary grilling result, with consistent burning without odors and white smoke. It ensures the most neutral grilling method, highlighting the true flavors of the grilled food.",
                    characteristics: {
                        title: 'Characteristics of Cuban Charcoal',
                        list: [
                            'Maximum burning duration: 4 to 6 hours',
                            'Easy ignition',
                            'Optimal performance-to-price ratio',
                            'Minimal ash residue',
                            'Odorless during burning',
                            'No sparks or smoke during burning',
                            'Excellent heating capacity',
                            'Charcoal made from hard and semi-hard wood',
                            'Environmentally friendly'
                        ]
                    },
                    packaging: {
                        title: 'Packaging of Cuban Charcoal',
                        content: 'Our charcoal is packaged in 15kg Polypropylene bags for added durability',
                        list: [
                            'Coco size: 4 – 20 cm',
                            'Cylindrical and semi-cylindrical shape',
                            'Zero presence of dust and foreign materials',
                            'Zero moisture',
                        ]
                    },
                    categories: {
                        title: 'Categories',
                        premium:{
                            title: 'Premium',
                            list: [
                                'Type: Marabú (Dichrostachys Cinerea)',
                                'Moisture: Maximum 6.0%',
                                'Ash: Maximum 3.0%',
                                'Granulation: 40 mm or more in diameter',
                                'Fixed carbon: More than 76%',
                                'Excellent calorific value: 7000 kcal/kg',
                            ]
                        },
                        low_granulation: {
                            title: 'Low Granulation',
                            list: [
                                'Type: Marabú (Dichrostachys Cinerea)',
                                'Moisture: Maximum 6.0%',
                                'Ash: Maximum 3.0%',
                                'Granulation: 20 mm - 30.9 mm',
                                'Fixed carbon: More than 76%',
                                'Excellent calorific value: 7000 kcal/kg',
                            ]
                        },
                        carbon: {
                            title: 'Carbon',
                            list: [
                                'Type: Marabú (Dichrostachys Cinerea)',
                                'Moisture: Maximum 6.0%',
                                'Ash: Maximum 3.0%',
                                'Granulation: Less than 10 mm',
                                'Fixed carbon: More than 76%',
                                'Excellent calorific value: 7000 kcal/kg',
                            ]
                        }
                    }
                }
                
            },
        },
        about: {
            title: 'About',
            content: [
                'Sigko International is a leading import and export company specializing in sourcing and supplying high-quality products worldwide.',
                'We are committed to the quality of our products, connecting international markets with the finest goods, such as Cuban lobster and Cuban charcoal.',
                'Our network and experience in global trade enable us to deliver our premium products with speed, reliable service, and highly competitive prices to our partners, making Sigko International a trusted name in the trade industry!'
            ]
            
        },
        contact: {
            title: 'Contact',
            options: {
                phone: 'Mobile Cuba',
                email: 'Email',
                whatsapp: 'Whatsapp'
            },
            content: {
                block: 'For inquiries related to import and export services, please feel free to reach out to us via:',
                subtitle: 'Our team is available to assist you with all your needs.'
            },
            form: {
                labels:{
                    name: 'Name',
                    email: 'Email',
                    phone: 'Phone',
                    message: 'Message',
                    submit: 'Submit'
                },
                placeholders:{
                    name: 'Enter your name (required)',
                    email: 'Enter your email (required)',
                    phone: 'Enter your phone (required)',
                    message: 'Enter your message (required)'
                },
                errors: {
                    requiredField: '{field} is required',
                    invalidPhone: 'Invalid phone number',
                },
                message: {
                    success: 'Message sent successfully',
                    error: 'There was a problem sending the message'
                }
            }
        }
    },
    el:{
        navigation:{
            home: 'Αρχικη',
            about: 'Σχετικα',
            products: 'Προϊοντα',
            contact: 'Επικοινωνια',
            footer: {
                home: 'Αρχική',
                about: 'Σχετικά',
                products: 'Προϊόντα',
                contact: 'Επικοινωνία'
            },
            link: 'Περισσότερες Πληροφορίες'
        },
        download: "Λήψη Παρουσίασης",
        languageSelector: {
            title: 'Γλωσσα',
            languages: {
                en: 'Αγγλικά',
                el: 'Ελληνικά',
                es: 'Ισπανικά'
            }
        },
        products: {
            title: 'Προϊόντα',
            header: {
                info: 'Πληροφορίες',
            },
            lobster: {
                name: 'Αστακός Κούβας',
                subtitle: 'Η γεύση και η ποιότητα της Καραϊβικής στο πιάτο σας!',
                description: 'Ο αστακός Panulirus argus έχει καστανοπράσινο κέλυφος με κίτρινες και λευκές κηλίδες, που γίνονται κόκκινες όταν μαγειρεύεται. Το κρέας του είναι λευκό, γλυκό, τρυφερό και με ήπια γεύση.',
                content: {
                    section_one: 'Ο αστακός Panulirus argus έχει καστανοπράσινο κέλυφος με κίτρινες και λευκές κηλίδες, που γίνονται κόκκινες όταν μαγειρεύεται. Το κρέας του είναι λευκό, γλυκό, τρυφερό και με ήπια γεύση.',
                    section_two: 'Αστακός σφαγμένος με επαγόμενο θάνατο σε διάλυμα μεταδιθειώδους νατρίου ή καλίου 0,65% και 0,5% κοινό αλάτι για περίπου 15 λεπτά που καταψύχεται πάνω στο πλοίο.',
                    categories: {
                        title: 'Κατηγορίες',
                        subtitle: 'Είδη;',
                        spiecies: [
                            {
                                title: 'Panulirus Argus',
                                subtitle: 'Εμπορικό σήμα'
                            },
                            {
                                title: 'Caribbean Queen',
                                subtitle: '*και τα δύο παγωμένα',
                                list: [
                                    'Ολόκληρος Αστακός',
                                    'Αστακοουρά'
                                ]
                            }
                        ]
                    },
                    packaging: {
                        title: 'Συσκευασία',
                        list: [
                            'O αστακός φορτώνεται στο λιμάνι Mariel της Αβάνας.',
                            'Θερμοκρασία αποθήκευσης: -18,0ºC Διάρκεια ζωής 24 μήνες.',
                            'Ο αστακός Panullirus Argus υπερτερεί σε περιεκτικότητα κρέατος.'
                        ],
                        content: [
                            {
                                title: 'Ολοκληρος Αστακος',
                                image: 'wholeLobster',
                                header: [
                                    'Γραμμάρια/τεμάχιο',
                                    'Κιλά/συσκευασία'
                                ],
                                content: [{
                                  key: '300/350',
                                  value: '10kg'
                                },{
                                  key: '350/400',
                                  value: '10kg'
                                },{
                                    key: '400/460',
                                    value: '10kg'
                                },{
                                    key: '460/520',
                                    value: '10kg'
                                },{
                                    key: '520/575',
                                    value: '10kg'
                                },{
                                    key: '575/630',
                                    value: '10kg'
                                },{
                                    key: '630/740',
                                    value: '10kg'
                                }, {
                                    key: '740/860',
                                    value: '10kg'
                                }, {
                                    key: '860/1100',
                                    value: '10kg'
                                }, {
                                    key: '1100/1300',
                                    value: '10kg'
                                }, {
                                    key: '1300/1500',
                                    value: '9kg'
                                }, {
                                    key: '1500/2000',
                                    value: '8kg'
                                }, {
                                    key: '2000/2500',
                                    value: '8kg'
                                }, {
                                    key: '2500/3000',
                                    value: '15kg'
                                }],
                                dimensions: {
                                    description: 'Συσκευασία: χαρτοκιβώτιο με διαστάσεις:',
                                    value: '565 x 405 x 165 mm'
                                }
                            },
                            {
                                title: 'Αστακοουρα',
                                header: [
                                    'Γραμμάρια/τεμάχιο',
                                    'Κιλά/συσκευασία'
                                ],
                                image: 'rawTail',
                                content: [{
                                    key: '3/4',
                                    value: '20kg'
                                }, {
                                    key: '4/5',
                                    value: '20kg'
                                }, {
                                    key: '5/6',
                                    value: '20kg'
                                }, {
                                    key: '6/7',
                                    value: '20kg'
                                }, {
                                    key: '7/8',
                                    value: '20kg'
                                }, {
                                    key: '8/9',
                                    value: '20kg'
                                }, {
                                    key: '9/10',
                                    value: '20kg'
                                }, {
                                    key: '10/12',
                                    value: '20kg'
                                }, {
                                    key: '2/14',
                                    value: '20kg'
                                }, {
                                    key: '14/16',
                                    value: '20kg'
                                }, {
                                    key: '16/20',
                                    value: '20kg'
                                }, {
                                    key: '20/24',
                                    value: '20kg'
                                }],
                                dimensions: {
                                    description: 'Συσκευασία: 20kg ανά χαρτοκιβώτιο.',
                                    value: ''
                                }
                            }
                        ]
                    }
                },
                products: {
                    offer: {
                        buttonText: 'Ληψη Προσφορας',
                        title: 'Ληψη Προσφορας',
                        form: {
                            sendButton: 'Αποστολή',
                            cancel: 'Ακύρωση',
                        }
                    },
                    wholeLobster: 'Ολοκληρος Αστακος',
                    rawTail: 'Αστακοουρα',
                    headers: {
                        weight: 'Γραμμάρια/τεμάχιο',
                        package: 'Κιλά/συσκευασία',
                        quantity: 'Ποσότητα σε κιλά',
                    }
                }
            },
            coals: {
                name: 'Κάρβουνα Κούβας',
                subtitle: '100% φυσικό προϊόν με υψηλή ποιότητα καύσης για καλύτερο και πιο υγιεινό ψήσιμο!',
                description: 'Τα κάρβουνα Κούβας είναι από τα καλύτερα κάρβουνα της αγοράς. Η καλύτερη επιλογή για επαγγελματίες και ιδιώτες. Ανάβουν εύκολα, χωρίς να πετάνε σπίθες και δεν αφήνουν τρίματα. Είναι 100% φυσικό προϊόν, χωρίς χημικά πρόσθετα.',
                content: {
                    section_one: 'Τα κάρβουνα Κούβας είναι από τα καλύτερα κάρβουνα της αγοράς. Η καλύτερη επιλογή για επαγγελματίες και ιδιώτες. Ανάβουν εύκολα, χωρίς να πετάνε σπίθες και δεν αφήνουν τρίματα. Είναι 100% φυσικό προϊόν, χωρίς χημικά πρόσθετα.',
                    section_two: 'Το περιεχόμενο τους αποτελείται από μεγάλα ατόφια κομμάτια, χωρίς υγρασία, σκόνη, τρίμματα και ξένα σώματα.',
                    section_three: 'Το καλέμι όπως γνωρίζουν οι παλαιότεροι, είναι το ξυλοκάρβουνο που παράγεται από νεαρά, μικρής διατομής, δένδρα και θάμνους. Το ιδανικό σχήμα του, κυλινδρικό και μακρύ, σε συνδυασμό με την ομοιογένεια και την καθαρότητα του, το κάνουν το τέλειο φυσικό ξυλοκάρβουνο. Είναι κατάλληλο για περιπτώσεις που χρειάζεται μικρό χρόνος αναμονής για την έναρξη ψησίματος αλλά και σταθερότητα καύσης με μεγάλη διάρκεια (4-6 ώρες).',
                    section_four: 'Τα κάρβουνα Κούβας παράγονται κατά βάση από το φυτό “dichrostachys cinerea”, με ονομασία που προέρχεται από την Αρχαία Ελλάδα (di-, ‘twice’), χροός (khroos, ‘color’), and στάχυς (stakhus) σήμερα είναι ευρέως γνωστό σαν marabu.',
                    section_five: 'H προέλευση του φυτού ανήκει στην Αφρική, εισήχθη δε για πρώτη φορά στην Καραϊβική, και συγκεκριμένα στην Κούβα, το 19ο αιώνα. Εμφανή χαρακτηριστικά του φυτού είναι τα δίχρωμα κυλινδρικά κλαδιά του. Το είδος του φυτού παράγει ένα ανθεκτικό και σκληρό ξύλο το οποίο καίγεται αργά χωρίς τοξικότητα κι έτσι συχνά χρησιμοποιείται για καυσόξυλα. Ιδανική είναι δε η χρήση του για την παραγωγή ξυλοκάρβουνου.',
                    section_six: 'Η ποικιλία marabu είναι ιδανική για επαγγελματίες που επιθυμούν ένα τέλειο γαστρονομικό αποτέλεσμα ψησίματος, με σταθερή καύση χωρίς οσμές και λευκούς καπνούς. Εξασφαλίζοντας τον πιο ουδέτερο τρόπο ψησίματος , αναδεικνύοντας τις πραγματικές γεύσεις του ψητού.',
                    characteristics: {
                        title: 'Χαρακτηριστικά',
                        list: [
                            'Μέγιστη διάρκεια καύσης 4 έως 6 ώρες',
                            'Εύκολο άναμμα',
                            'Βέλτιστη σχέση απόδοσης – τιμής',
                            'Ελάχιστο ποσοστό στάχτης',
                            'Αοσμο κατά την καύση',
                            'Χωρίς σπίθες και καπνό κατά την καύση',
                            'Αριστη θερμαντική ικανότητα',
                            'Κάρβουνο προερχόμενο από σκληρό και ημίσκληρο ξύλο',
                            'Φιλικό στο περιβάλλον',
                        ]
                    },
                    packaging: {
                        title: 'Συσκευασία',
                        content: 'Τα καρβουνα μας ερχονται σε συσκευασια πολυπροπυλενιου των 15 κιλων για μεγαλυτερη αντοχη',
                        list: [
                            'Κοκκομετρία 4 – 20cm',
                            'Κυλινδρική και ημικυλινδρική μορφή',
                            'Μηδενική παρουσία σκόνης και ξένων σωμάτων',
                            'Μηδενική υγρασία',
                        ]
                    },
                    categories: {
                        title: 'Κατηγορίες',
                        premium:{
                            title: 'Premium',
                            list: [
                                'Είδος: Marabú (Dichrostachys Cinerea)',
                                'Υγρασία: μέγιστο 6,0%',
                                'Στάχτη: 3.0% Μέγιστο',
                                'Κοκκομετρία: 40 mm ή περισσότερο διαμέτρου',
                                'Σταθερό άνθρακα: περισσότερο από 76%',
                                'Εξαιρετική θερμιδική ισχύ: 7000 kcal / kg',
                            ]
                        },
                        low_granulation: {
                            title: 'Χαμηλή κοκκομετρία',
                            list: [
                                'Είδος: Marabú (Dichrostachys Cinerea)',
                                'Υγρασία: μέγιστο 6,0%',
                                'Στάχτη: 3.0% Μέγιστο',
                                'Κοκκομετρία: 20 mm -30,9 mm',
                                'Σταθερό άνθρακα: περισσότερο από 76%',
                                'Εξαιρετική θερμιδική ισχύ: 7000 kcal / kg',
                            ]
                        },
                        carbon: {
                            title: 'Άνθρακα',
                            list: [
                                'Είδος: Marabú (Dichrostachys Cinerea)',
                                'Υγρασία: μέγιστο 6,0%',
                                'Στάχτη: 3.0% Μέγιστο',
                                'Κοκκομετρία: λιγότερο από 10 mm',
                                'Σταθερό άνθρακα: περισσότερο από 76%',
                                'Εξαιρετική θερμιδική ισχύ: 7000 kcal / kg',
                            ]
                        }
                    }
                }
            }
        },
        about: {
            title: 'Σχετικά',
            content: [
                'Η Sigko International είναι μια κορυφαία εταιρεία εισαγωγής και εξαγωγής που εξειδικεύεται στην προμήθεια και παροχή υψηλής ποιότητας προϊόντων σε όλο τον κόσμο.',
                'Δεσμευόμαστε για την υψηλή ποιότητα των προϊόντων μας και συνδέουμε διεθνείς αγορές με τα καλύτερα αγαθά, όπως ο αστακός Κούβας και τα κάρβουνα Κούβας.',
                'Το δίκτυο και η εμπειρία μας στο παγκόσμιο εμπόριο μας επιτρέπουν να παρέχουμε τα υψηλης ποιότητας προϊόντα μας με ταχύτητα, αξιοπιστία στην εξυπηρέτηση και πολύ ανταγωνιστικές τιμές στους συνεργάτες μας, κάνοντας τη Sigko International ένα αξιόπιστο όνομα στον κλάδο του εμπορίου!'
            ]
            
        },
        contact: {
            title: 'Επικοινωνία',
            options: {
                phone: 'Mobile Cuba',
                email: 'Email',
                whatsapp: 'Whatsapp'
            },
            content: {
                block: 'Για ερωτήσεις που σχετίζονται με υπηρεσίες εισαγωγής και εξαγωγής, μη διστάσετε να επικοινωνήσετε μαζί μας μέσω:',
                subtitle: 'Η ομάδα μας είναι διαθέσιμη για να σας βοηθήσει με όλες τις ανάγκες σας.'
            },
            form: {
                labels:{
                    name: 'Όνομα',
                    email: 'Email',
                    phone: 'Τηλέφωνο',
                    message: 'Μήνυμα',
                    submit: 'Υποβολή',
                },
                placeholders:{
                    name: 'Εισάγετε το όνομά σας (απαιτείται)',
                    email: 'Εισάγετε το email σας (απαιτείται)',
                    phone: 'Εισάγετε το τηλέφωνό σας (απαιτείται)',
                    message: 'Εισάγετε το μήνυμά σας (απαιτείται)',
                    submit: 'Υποβολή'
                },
                errors: {
                    requiredField: 'Το πεδίο {field} είναι υποχρεωτικό',
                    invalidPhone: 'Μη έγκυρος αριθμός τηλεφώνου',
                },
                message:{
                    success: 'Το μήνυμα στάλθηκε επιτυχώς',
                    error: 'Υπήρξε πρόβλημα κατά την αποστολή του μηνύματος'
                }
            }
        }
    },
    "es": {
    "navigation": {
        "home": "Inicio",
        "about": "Acerca de",
        "products": "Productos",
        "contact": "Contacto",
        "footer": {
        "home": "Inicio",
        "about": "Acerca de",
        "products": "Productos",
        "contact": "Contacto"
        },
        "link": "Más información"
    },
    "download": "Descargar Presentación",
    "languageSelector": {
        "title": "Idioma",
        "languages": {
        "en": "Inglés",
        "el": "Griego",
        "es": "Español"
        }
    },
    "products": {
        "title": "Productos",
        "header": {
        "info": "Información"
        },
        "lobster": {
            "name": "Langosta Cubana",
            "subtitle": "¡El sabor y la calidad del Caribe en tu plato!",
            "description": "La Langosta Panulirus argus tiene un caparazón de color verde parduzco con manchas amarillas y blancas, que se vuelve rojo cuando se cocina. Su carne es blanca, dulce, tierna y de sabor suave.",
            "content": {
              "section_one": "La Langosta Panulirus argus tiene un caparazón de color verde parduzco con manchas amarillas y blancas, que se vuelve rojo cuando se cocina. Su carne es blanca, dulce, tierna y de sabor suave.",
              "section_two": "Langosta sacrificada con muerte inducida en solución de 0,65% de metabisulfito de sodio o potasio y 0,5% de sal común durante un tiempo aproximado de 15 minutos, y congelada a bordo.",
              "categories": {
                "title": "Categorías",
                "subtitle": "Especies:",
                "spiecies": [
                  {
                    "title": "Panulirus Argus",
                    "subtitle": "Marca Registrada;"
                  },
                  {
                    "title": "Reina del Caribe",
                    "subtitle": "*ambos congelados",
                    "list": [
                      "Langosta Entera",
                      "Cola de Langosta"
                    ]
                  }
                ]
              },
              "packaging": {
                "title": "Embalaje",
                "list": [
                  "La langosta se carga en el Puerto de “La Habana” (Mariel)",
                  "Temperatura de almacenamiento: -18,0°C, Durabilidad 24 meses.",
                  "La Langosta Panulirus Argus sobresale en contenido de carne."
                ],
                "content": [
                    {
                        "title": "Langosta entera",
                        "image": "wholeLobster",
                        "header": [
                            "Gramos/unidad",
                            "Kg/paquete"
                        ],
                        "content": [
                            {
                                "key": "300/350",
                                "value": "10kg"
                            },
                            {
                                "key": "350/400",
                                "value": "10kg"
                            },
                            {
                                "key": "400/460",
                                "value": "10kg"
                            },
                            {
                                "key": "460/520",
                                "value": "10kg"
                            },
                            {
                                "key": "520/575",
                                "value": "10kg"
                            },
                            {
                                "key": "575/630",
                                "value": "10kg"
                            },
                            {
                                "key": "630/740",
                                "value": "10kg"
                            },
                            {
                                "key": "740/860",
                                "value": "10kg"
                            },
                            {
                                "key": "860/1100",
                                "value": "10kg"
                            },
                            {
                                "key": "1100/1300",
                                "value": "10kg"
                            },
                            {
                                "key": "1300/1500",
                                "value": "9kg"
                            },
                            {
                                "key": "1500/2000",
                                "value": "8kg"
                            },
                            {
                                "key": "2000/2500",
                                "value": "8kg"
                            },
                            {
                                "key": "2500/3000",
                                "value": "15kg"
                            }
                        ],
                        "dimensions": {
                            "description": "Paquete: caja de cartón con dimensiones:",
                            "value": "565 x 405 x 165 mm"
                        }
                    },
                    {
                        "title": "Cola de langosta",
                        "header": [
                            "Gramos/unidad",
                            "Kg/paquete"
                        ],
                        "image": "rawTail",
                        "content": [
                            {
                                "key": "3/4",
                                "value": "20kg"
                            },
                            {
                                "key": "4/5",
                                "value": "20kg"
                            },
                            {
                                "key": "5/6",
                                "value": "20kg"
                            },
                            {
                                "key": "6/7",
                                "value": "20kg"
                            },
                            {
                                "key": "7/8",
                                "value": "20kg"
                            },
                            {
                                "key": "8/9",
                                "value": "20kg"
                            },
                            {
                                "key": "9/10",
                                "value": "20kg"
                            },
                            {
                                "key": "10/12",
                                "value": "20kg"
                            },
                            {
                                "key": "2/14",
                                "value": "20kg"
                            },
                            {
                                "key": "14/16",
                                "value": "20kg"
                            },
                            {
                                "key": "16/20",
                                "value": "20kg"
                            },
                            {
                                "key": "20/24",
                                "value": "20kg"
                            }
                        ],
                        "dimensions": {
                            "description": "Paquete: 20kg por caja de cartón.",
                            "value": ""
                        }
                    }
                ]
                
              }
            },
            "products": {
                "offer": {
                    "buttonText": "Obtener una oferta",
                    "title": "Obtener una oferta",
                    "form": {
                        "sendButton": "Enviar",
                        "cancel": "Cancelar"
                    }
                },
                "wholeLobster": "Langosta entera",
                "rawTail": "Cola cruda",
                "headers": {
                    "weight": "gr/unidad",
                    "package": "kg/paquete",
                    "quantity": "Cantidad en kg"
                }
            }           
          },
        "coals": {
        "name": "Carbón de Cuba",
        "subtitle": "¡Producto 100% natural con quema de alta calidad para una parrillada mejor y más saludable!",
        "description": "El carbón de Cuba está entre los mejores carbones del mercado. La mejor opción para profesionales y particulares. Se enciende fácilmente, sin chispear, y no deja residuos. Es un producto 100% natural, libre de aditivos químicos.",
        "info": "El carbón es una excelente manera de cocinar tus alimentos al aire libre.",
        "content": {
            "section_one": "El carbón de Cuba está entre los mejores carbones del mercado. La mejor opción para profesionales y particulares. Se enciende fácilmente, sin chispear, y no deja residuos. Es un producto 100% natural, libre de aditivos químicos.",
            "section_two": "Se compone de piezas grandes y sólidas, sin humedad, polvo, residuos ni cuerpos extraños.",
            "section_three": "El carbón, como lo conoce la generación anterior, está hecho de árboles jóvenes de pequeño diámetro y arbustos. Su forma ideal, cilíndrica y larga, combinada con su uniformidad y pureza, lo convierte en el carbón natural perfecto. Es adecuado para casos donde se necesita un corto tiempo de espera para comenzar a asar, así como para una combustión estable con una larga duración (4-6 horas).",
            "section_four": "El carbón de Cuba se produce principalmente a partir de la planta 'dichrostachys cinerea', cuyo nombre proviene de la Antigua Grecia (di-, 'dos veces'), χροός (kroos, 'color') y στάχυς (stachys), y que ahora se conoce ampliamente como marabú.",
            "section_five": "La planta es originaria de África y se introdujo por primera vez en el Caribe, específicamente en Cuba, en el siglo XIX. Las características notables de la planta son sus ramas cilíndricas de dos tonos. La especie de planta produce una madera dura y duradera que arde lentamente sin toxicidad, por lo que se utiliza a menudo como leña. Es ideal para la producción de carbón.",
            "section_six": "La variedad de marabú es ideal para profesionales que buscan un resultado de parrilla culinaria perfecto, con una combustión constante sin olores ni humo blanco. Garantiza el método de asado más neutral, destacando los verdaderos sabores de los alimentos a la parrilla.",
            "characteristics": {
            "title": "Características del Carbón Cubano",
            "list": [
                "Duración máxima de combustión: 4 a 6 horas",
                "Encendido fácil",
                "Relación óptima de rendimiento-precio",
                "Mínimo residuo de ceniza",
                "Sin olor durante la combustión",
                "Sin chispas ni humo durante la combustión",
                "Excelente capacidad de calentamiento",
                "Carbón hecho de madera dura y semidura",
                "Amigable con el medio ambiente"
            ]
            },
            "packaging": {
            "title": "Empaque del Carbón Cubano",
            "content": "Nuestro carbón está empaquetado en bolsas de polipropileno de 15 kg para mayor durabilidad",
            "list": [
                "Tamaño Coco: 4 – 20 cm",
                "Forma cilíndrica y semicilíndrica",
                "Cero presencia de polvo y materiales extraños",
                "Cero humedad"
            ]
            },
            "categories": {
            "title": "Categorías",
            "premium": {
                "title": "Premium",
                "list": [
                "Tipo: Marabú (Dichrostachys Cinerea)",
                "Humedad: Máximo 6.0%",
                "Ceniza: Máximo 3.0%",
                "Granulación: 40 mm o más de diámetro",
                "Carbono fijo: Más del 76%",
                "Valor calórico excelente: 7000 kcal/kg"
                ]
            },
            "low_granulation": {
                "title": "Baja Granulación",
                "list": [
                "Tipo: Marabú (Dichrostachys Cinerea)",
                "Humedad: Máximo 6.0%",
                "Ceniza: Máximo 3.0%",
                "Granulación: 20 mm - 30.9 mm",
                "Carbono fijo: Más del 76%",
                "Valor calórico excelente: 7000 kcal/kg"
                ]
            },
            "carbon": {
                "title": "Carbón",
                "list": [
                "Tipo: Marabú (Dichrostachys Cinerea)",
                "Humedad: Máximo 6.0%",
                "Ceniza: Máximo 3.0%",
                "Granulación: Menos de 10 mm",
                "Carbono fijo: Más del 76%",
                "Valor calórico excelente: 7000 kcal/kg"
                ]
            }
            }
        }
        }
    },
    "about": {
        "title": "Acerca de",
        "content": [
            "Sigko International es una empresa líder en importación y exportación, especializada en la adquisición y suministro de productos de alta calidad en todo el mundo.",
            "Estamos comprometidos con la calidad de nuestros productos, conectando mercados internacionales con los mejores bienes, como la langosta cubana y el carbón cubano.",
            "Nuestra red y experiencia en el comercio global nos permiten ofrecer nuestros productos premium con rapidez, servicio confiable y precios altamente competitivos a nuestros socios, haciendo de Sigko International un nombre de confianza en la industria comercial."
        ]
    },
    "contact": {
        "title": "Contacto",
        "options": {
        "phone": "Cuba móvil",
        "email": "Correo electrónico",
        "whatsapp": "Whatsapp"
        },
        "content": {
        "block": "Para consultas relacionadas con servicios de importación y exportación, no dude en ponerse en contacto con nosotros a través de:",
        "subtitle": "Nuestro equipo está disponible para ayudarle con todas sus necesidades."
        },
        "form": {
        "labels": {
            "name": "Nombre",
            "email": "Correo electrónico",
            "phone": "Teléfono",
            "message": "Mensaje",
            "submit": "Enviar"
        },
        "placeholders": {
            "name": "Ingrese su nombre (requerido)",
            "email": "Ingrese su correo electrónico (requerido)",
            "phone": "Ingrese su teléfono (requerido)",
            "message": "Ingrese su mensaje (requerido)"
        },
        "errors": {
            "requiredField": "{field} es requerido",
            "invalidPhone": "Número de teléfono no válido"
        },
        "message": {
            "success": "Mensaje enviado con éxito",
            "error": "Hubo un problema al enviar el mensaje"
        }
        }
    }
    }

};

// Check if a locale is saved in localStorage, otherwise default to 'el'
const savedLocale = localStorage.getItem('locale') || 'el';

// Create the Vue I18n instance
const i18n = new VueI18n({
    locale: savedLocale, // Set default locale
    fallbackLocale: 'en', // Set fallback locale
    messages, // Set locale messages
  });
  
  export default i18n;
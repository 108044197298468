import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueMeta from 'vue-meta';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faPhone, faEnvelope, faGlobe, faArrowUp, faBox, faFilePdf, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp,faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'


library.add(faUser, faPhone, faEnvelope, faGlobe, faWhatsapp, faWhatsappSquare, faArrowUp, faBox, faFilePdf, faFileInvoice );

require('@/assets/main.scss');

import i18n from './i18n'; // Import the i18n configuration

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  i18n, // Add the i18n plugin
  router,
  store,
  VueMeta,
  render: function (h) { return h(App) }
}).$mount('#app')

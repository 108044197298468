<template>
  <div id="app">
    <PageLoader/>
    <Navigation v-if="!$route.meta.hideNavbarAndFooter"/>
    <router-view/>
    <FooterComponent v-if="!$route.meta.hideNavbarAndFooter"/>
    <ScrollToTop/>
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>


<script>
import PageLoader from '@/components/PageLoader.vue'
import Navigation from '@/components/Navigation.vue';
import FooterComponent from '@/components/FooterComponent.vue'
import ScrollToTop from '@/components/ScrollToTop.vue';

export default {
  name: 'App',
  components: {
    PageLoader,
    Navigation,
    FooterComponent,
    ScrollToTop
  },
};
</script>
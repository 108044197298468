<template>
    <footer class="section">
      <div class="container">
        <div class="pb-5 is-flex is-flex-wrap-wrap is-justify-content-between is-align-items-center">
          <div class="mr-auto mb-2">
            <a class="is-inline-block" href="#">
              <!--
              <img class="image" src="https://bulma.io/images/bulma-logo.png" alt="" width="96px">--></a>
          </div>
          <div>
            <ul class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center">
              <div class="buttons">
                <router-link to="/" class="button is-white is-small">{{ $t('navigation.footer.home') }}</router-link>
                <router-link to="/products" class="button is-white is-small">{{ $t('navigation.footer.products') }}</router-link>
                <router-link to="/about" class="button is-white is-small">{{ $t('navigation.footer.about') }}</router-link>
                <router-link to="/contact" class="button is-white is-small">{{ $t('navigation.footer.contact') }}</router-link>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="pt-5" style="border-top: 1px solid #dee2e6;"></div>
      <div class="container">
        <div class="columns is-mobile">
          <div class="column is-half is-pulled-left">
            <img class="image" src="@/assets/logo.png"  width="102"/>
          </div>
          <div class="column is-half 4 has-text-right">
            <p class="is-unselectable is-6 pt-4" style="font-size: 0.65rem">
              Developed by:
              <a href="https://ngmlab.dev" target="_blank" class="has-text-grey-darker">
                <span class="has-text-weight-bold is-clickable">
                  <span class="has-text-shadow is-small">
                    ngmlab
                  </span>
                  <span class="has-text-ngmlab-yellow">
                    &#x2022; 
                  </span>
                  <span class="ngmlab-logo has-text-grey-darker">
                    DEV
                  </span>
                </span>
              </a>  
            </p>
          </div>
        </div>
      </div>
    </footer>
</template>

<style lang="scss" scoped>
$sigko-logo-blue: #253d6d;
$sigko-orange: #d6682d;
$sigko-dark-blue: #102e54;
$sigko-orange: #d6682d;

$ngmlab-purple: #AF47D2;
$ngmlab-yellow: #FFDB00;

@use "bulma/sass/utilities/mixins";

@include mixins.until(1023px) {
  .pt-4 {
    padding-top: unset !important;
  }
}
span {
  &.has-text-ngmlab-purple {
      color: $ngmlab-purple;
  }
  &.has-text-ngmlab-yellow {
      color: $ngmlab-yellow;
  }
  &.ngmlab-logo {
      transition: all 1.5s ease;
      background-color: $ngmlab-yellow;
      padding: 0.15rem 0.3rem;
      border-radius: 0.25rem;
  }
}

.has-text-sigko-blue {
    color: $sigko-dark-blue;
    font-size: 1.15rem;
    letter-spacing: 0.6rem;
    font-weight: bolder;
    padding: 0;
    margin: 0;
}
.has-text-sigko-oragne {
    color: $sigko-orange;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 0;
    margin: 0;
    margin-top: -0.25rem;
}
</style>

<script>
export default {
  name: 'FooterComponent',
  computed: {
    currentYear() {
      // Get the current year using JavaScript's Date object
      return new Date().getFullYear();
    },
    // other computed properties
  },
};
</script>
<template>
    <div :class="containerClass">
        <div class="mx-auto box pt-5 pr-6 pl-6 pb-2 has-background-white has-text-left">
            <h4 class="title is-size-2 has-text-weight-bold">
                <router-link :to="route" >{{ title }}</router-link>
            </h4>
            <hr class="hr-sigko is-orange"/>
            <figure v-if="imageSrc && imageSrc.trim() !== ''"  class="image is-128x128 m-auto">
                <img class="is-rounded" :src="imageSrc" />
            </figure>
            <p class="has-text-left is-size-5">{{ description }}</p>
            <hr v-if="route && route.trim() !== ''"/>
            <div v-if="route && route.trim() !== ''" class="block pb-4">
                <router-link :to="route" class="button is-link is-fullwidth">{{ routeButtonText }}</router-link>
            </div>
        </div>
        </div>
</template>

<style lang="scss" scoped>
$sigko-dark-blue: #102e54;
$sigko-orange: #d6682d;
.title {
    color: $sigko-dark-blue;
    a {
        color: $sigko-dark-blue;
    }
}

$sigko-new-blue-hover: rgb(37, 61, 109, 0.9);
.button {
    &.is-link{
        background-color: $sigko-dark-blue;
        color: white;
        font-weight: bold;
        &:hover {
          // color: $sigko-orange;
          background-color: $sigko-new-blue-hover;
        }
    }
}
</style>

<script>
export default{
    name: 'FrontViewComponent',
    props:{
        title:{
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        route: {
            type: String,
            required: false
        },
        routeButtonText: {
            type: String,
            required: true
        },
        slideDirection: {
            type: String,
            required: false,
            default: 'left'
        },
        imageSrc: {
            type: String,
            required: false,
            default: 'https://bulma.io/assets/images/placeholders/128x128.png'
        },
        hasImage:{
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            containerClass: this.slideDirection == 'left' ?  'column is-6 ml-auto slide-in-container': 'column is-6 ml-auto slide-in-container-from-right',
        }
    }
}
</script>
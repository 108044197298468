<template>

<nav class="navbar is-spaced is-sticky" role="navigation" aria-label="main navigation">
    <div class="container">
        <div class="navbar-brand">
            <router-link to="/" class="navbar-item">
                &nbsp;
                <!-- <span class="brand-logo"><span class="is-uppercase">Sigko</span> International</span> -->
                <!-- <img src="@/assets/sigko-logo.png" alt="Brand Logo" width="152" class="brand-logo"> -->
            </router-link>
            <a class="navbar-burger" role="button" aria-label="menu" aria-expanded="false"  data-target="navigation">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div id="navigation" class="navbar-menu">
            <div class="navbar-start">
            </div>
            <div class="navbar-end">
                <!-- <router-link to="/" class="navbar-item">{{ $t('navigation.home') }}</router-link> -->
                <router-link to="/products" class="navbar-item">{{ $t('navigation.products') }}</router-link>
                <router-link to="/about" class="navbar-item">{{ $t('navigation.about') }}</router-link>
                <router-link to="/contact" class="navbar-item">{{ $t('navigation.contact') }}</router-link>
                <LanguageSelector/>
            </div>
        </div>
    </div>
</nav>

</template>

<style lang="scss">
$sigko-blue: #1f5daa;
// $sigko-blue: #273d78;
$sigko-blue-new: #253d6d;
$sigko-orange: #e7691f;
$sigko-black: #343031;

@use "bulma/sass/utilities/mixins";

@include mixins.until(1023px) {
    .navbar {
        &.is-spaced {
            padding: var(--bulma-navbar-padding-vertical) 0 !important;
        }

        .navbar-burger {
            color: $sigko-orange;
        }
    }
    .navbar-menu{
        opacity: 0;
        &.is-active {
            position: absolute;
            top: 4.35rem;
            width: 100%;
            animation: slideInFromTop 0.5s forwards;
        }
    }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.navbar {
    &.is-sticky {
        position: sticky;
        top: 0;
        z-index: 9;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        border-bottom: solid 0.1rem $sigko-orange;
    }
    // padding: 1.5rem 0.75rem;
    &.is-link {
        .navbar-burger {
            color: white;
        }
    }

    .navbar-brand {
        .navbar-item {
            background: url('@/assets/logo.png') no-repeat center center;
            background-size: cover;
            // width: 165px;
            width: 144px;
        }
        .brand-logo {
            // font-size: 1.75rem;
            // font-weight: bold;
            // // text-transform: uppercase;
            // // color: $sigko-blue;
            // span {
            //     transition: background-color 0.3s ease, filter 0.3s ease, color 0.3s ease; /* Smooth transition */
            //     border: solid 0.15rem $sigko-blue;
            //     color: $sigko-blue;
            //     padding: 0.25rem 0.25rem;
            //     border-radius: 0.5rem;
            // }
            // &:hover {
            //     span {
            //         background-color: $sigko-blue;
            //         color: white;
            //     }
            // }

        }
        .navbar-item {
            &:hover{
                background-color: transparent !important;
            }
            &:active{
                background-color: transparent !important;
            }
        }
    }

    .navbar-item {
        border-radius: 0.5rem;
        color: $sigko-blue-new;
        .navbar-link {
            color: $sigko-blue-new;
            &:hover {
                color: $sigko-orange;
            }
        }
        &:hover {
            background-color: transparent;
            color: $sigko-orange;
        }
        &:active {
            background-color: transparent;
            color: $sigko-orange;
        }
        &:focus {
            background-color: transparent;
        }
    }
    .navbar-end {
        .navbar-item {
            font-size: 0.85rem;
            text-transform: uppercase;
            font-weight: bold;
        }    
    }
    .navbar-dropdown {
        .navbar-item {
            .normal-flag {
                margin: unset;
            }
        }
    }
}
</style>

<script>
import { mapGetters } from 'vuex';
import LanguageSelector from './LanguageSelector.vue';

export default {
name: 'NavBar',
mounted() {
      // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
        });
    });

    // Close the navbar when a link is clicked
    const navbarLinks = document.querySelectorAll('.navbar-item');
    navbarLinks.forEach(function (navbarLink) {
        navbarLink.addEventListener('click', function () {
            const navbarMenu = document.getElementById('navigation');
            const navbarBurger = document.querySelector('.navbar-burger');
            if (navbarMenu.classList.contains('is-active')) {
                navbarMenu.classList.remove('is-active');
                navbarBurger.classList.remove('is-active');
            }
        });
    });
},
data() {
    return {
        navBarState: false,
    };
},
computed: {
    ...mapGetters(['getNavBarState']),
    isNavbarActive () {
        return this.getNavBarState;
    }
},
methods: {
    toggleNavbar() {
        console.log('Toggle Navbar method called');
        this.$store.commit('setNavBarActive', !this.isNavbarActive);
    },
},
components: {
    LanguageSelector
}
};
</script>
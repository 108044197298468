<template>
    <div v-if="isVisible" class="scroll-to-top button is-medium is-link is-light" @click="scrollToTop">
        <span class="icon">
            <font-awesome-icon icon="arrow-up" />
        </span>
    </div>
</template>

<style scoped lang="scss">

$sigko-orange: #d6682d;
$sigko-blue-new: #253d6d;
$sigko-new-blue-hover: rgb(37, 61, 109, 0.9);

.button {
  background-color: $sigko-blue-new;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  &:hover {
    background-color: $sigko-new-blue-hover;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
</style>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 300; // Show button after scrolling down 300px
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>